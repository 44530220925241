package com.evertwoud.portfolio.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.evertwoud.portfolio.components.widgets.TablerIcon
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.init.setSilkVariables
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import com.varabyte.kobweb.silk.theme.toSilkPalette
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px

@Composable
fun PageLayout(
    title: String, backRoute: String? = null, content: @Composable ColumnScope.() -> Unit
) {
    val ctx = rememberPageContext()
    var colorMode by ColorMode.currentState

    LaunchedEffect(title) {
        document.title = "Evert Woud - $title"
    }

    Box(
        modifier = Modifier.fillMaxSize(),
    ) {
        Column(
            modifier = Modifier.fillMaxSize(),
            content = content
        )

        Row(
            modifier = Modifier.padding(16.px).fillMaxWidth()
                .color(colorMode.opposite.toSilkPalette().color)
            ,

            horizontalArrangement = when (backRoute != null) {
                true -> Arrangement.SpaceBetween
                false -> Arrangement.End
            }
        ) {
            if (backRoute != null) {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .background(colorMode.opposite.toSilkPalette().background)
                        .padding(12.px)
                        .clip(Circle())
                        .cursor(Cursor.Pointer)
                        .onClick {
                            ctx.router.navigateTo(backRoute)
                        },
                ) {
                    TablerIcon(
                        modifier = Modifier.fillMaxSize(),
                        name = "arrow-up-left",
                        size = FontSize.Medium
                    )
                }
            }
            Box(
                modifier = Modifier
                    .background(colorMode.opposite.toSilkPalette().background)
                    .padding(12.px)
                    .clip(Circle())
                    .cursor(Cursor.Pointer)
                    .onClick {
                        colorMode = colorMode.opposite
                    }, contentAlignment = Alignment.Center
            ) {
                TablerIcon(
                    modifier = Modifier.fillMaxSize(),
                    name = when (colorMode) {
                        ColorMode.LIGHT -> "moon"
                        ColorMode.DARK -> "sun"
                    },
                    size = FontSize.Medium
                )
            }
        }
    }
}
