package com.evertwoud.portfolio.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.core.Page
import com.evertwoud.portfolio.components.layouts.PageLayout
import com.evertwoud.portfolio.components.widgets.ContentBlock
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun ContactPage() {
    var colorMode by ColorMode.currentState

    PageLayout("Contact", backRoute = "/") {
        SimpleGrid(
            modifier = Modifier.fillMaxSize().padding(top = 64.px, bottom = 16.px, leftRight = 16.px),
            numColumns = numColumns(
                base = 1,
                sm = 2,
            )
        ) {
            ContentBlock(
                modifier = Modifier.fillMaxSize(),
                title = "LINKEDIN",
                link = "https://www.linkedin.com/in/evertwoud/"
            )
            ContentBlock(
                modifier = Modifier.fillMaxSize(),
                title = "GITHUB",
                link = "https://github.com/EvertW"
            )
            ContentBlock(
                modifier = Modifier.fillMaxSize(),
                title = "INSTAGRAM",
                link = "https://www.instagram.com/evertwoud"
            )
            ContentBlock(
                modifier = Modifier.fillMaxSize(),
                title = "EMAIL",
                link = "mailto:contact@evertwoud.com",
                strategy = OpenLinkStrategy.IN_PLACE
            )
        }
    }
}
