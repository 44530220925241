package com.evertwoud.portfolio.data

object Projects {

    val projects = listOf(
        Project(
            id = "bolero",
            title = "Bolero (KBC)",
            description = "Bolero is the online trading platform of KBC. The platform allows its users to invest and trade in a large set of asset types.",
            image = "https://icapps.com/uploads/site/_AUTOx800_fit_center-center_none/52934/Bolero-iPad-Watchlist.webp",
            url = "https://icapps.com/cases/bolero",
            downloadUrl = "https://play.google.com/store/apps/details?id=com.kbcsecurities.bolero",
            categories = listOf(
                "Investing",
                "Finance",
                "Stocks"
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "quest",
            title = "Quest (bol.com)",
            description = "App used to help determine & enhance the condition of products send to the return center of the e-commerce platform bol.com",
            image = "https://www.groenewout.nl/wp-content/uploads/2021/10/BRC-13.jpg",
            url = "https://icapps.com/cases/bol-com-quest-app",
            categories = listOf(
                "Logistics",
                "E-Commerce",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "monizze",
            title = "Monizze",
            description = "App to provide users insights in their financial data from the Monizze platform.",
            image = "https://icapps.com/uploads/site/_AUTOx800_fit_center-center_none/59016/Holding-iPhone-X-mockup-Mockuuups-Studio.webp",
            url = "https://icapps.com/cases/monizze",
            downloadUrl = "https://play.google.com/store/apps/details?id=be.monizze.app",
            categories = listOf(
                "Finance",
                "Shopping",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "klm-catering",
            title = "KLM Catering Services",
            description = "App used by the drivers of KLM Catering Services to handle jobs (supply aircraft).",
            image = "https://icapps.com/uploads/site/_AUTOx400_fit_center-center_none/20390/Case-KLM1.webp",
            url = "https://icapps.com/cases/klm-catering-services",
            categories = listOf(
                "Logistics",
                "Flights",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "pickup",
            title = "Pickup (bol.com)",
            description = "App used in bol.com pick-up-points to efficiently scan/manage packages when customers come to collect their package(s).",
            image = "https://images.gondola.be/old/2019-04/ah_bolcom_servicepunt_amsterdam_1_5.jpg?auto=format&fit=crop&h=420&ixlib=php-1.1.0&q=75&w=800&s=df9bd77eaf53e3770f1407647b1a1e1f",
            url = "https://icapps.com/cases/bol-com",
            categories = listOf(
                "Logistics",
                "E-Commerce",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "elizabeth-on-food",
            title = "Elizabeth on Food",
            description = "Provide food advice in (and around) Amsterdam by food critic Elizabeth Auerbach.",
            image = "https://www.d-tt.nl/assets/images/apps/eof-overview.png",
            url = "https://www.d-tt.nl/apps/elizabeth-on-food-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.elizabethonfood",
            categories = listOf(
                "Food",
                "Culture",
            ),
            tasks = listOf(
                "Project Lead",
                "Android Development"
            )

        ),
        Project(
            id = "real-time-road-inspection",
            title = "Real-Time Road Inspection",
            description = "App to record infrastructure information in real-time using the camera of the device.",
            image = "https://www.d-tt.nl/assets/images/apps/rtri-overview2.png",
            url = "https://www.d-tt.nl/apps/real-time-road-inspection",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.rtri",
            categories = listOf(
                "Inspection",
                "Infrastructure",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "e-moodboard",
            title = "E-Moodboard",
            description = "Keep track of emotions in a diary. Allow the scanning of a physical board using text recognition.",
            image = "https://www.d-tt.nl/assets/images/apps/emoodboard-new-overview.png",
            url = "https://www.d-tt.nl/apps/e-moodboard",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.moodboard",
            categories = listOf(
                "Emotions",
                "Diary",
            ),
            tasks = listOf(
                "Project Lead",
                "Android Development"
            )
        ),
        Project(
            id = "jellinek",
            title = "Jellinek",
            description = "Connect (ex) Jellinek program participants through live chats and groups.",
            image = "https://www.d-tt.nl/assets/images/apps/xjellinek-new-overview.png.pagespeed.ic.HdoSb36hp6.webp",
            url = "https://www.d-tt.nl/apps/jellinekminnesota",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.jellinek",
            categories = listOf(
                "Communication",
                "Rehabilitation",
            ),
            tasks = listOf(
                "Project Lead",
                "Android Development"
            )
        ),
        Project(
            id = "healthy-football-league",
            title = "Healthy Football League",
            description = "App that tracks users fitness activities to collect points and have a competition with friends.",
            image = "https://www.d-tt.nl/assets/images/apps/xhealthyfootballleague-new-overview.png.pagespeed.ic.XCu-EhaFU3.webp",
            url = "https://www.d-tt.nl/apps/healthy-football-league-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.android.efdn",
            categories = listOf(
                "Communication",
                "Sport",
                "Activity tracking",
                "Football",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "locum",
            title = "Locum",
            description = "App that provides a solution for the deposit of services for general- and freelance practitioners.",
            image = "https://www.d-tt.nl/assets/images/apps/locum-new-overview.png",
            url = "https://www.d-tt.nl/apps/locum",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.locum",
            categories = listOf(
                "Healthcare",
                "Appointments",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "vono",
            title = "VoNo",
            description = "App that allows users to quickly take memo's (voice/text/picture/video) to an email address.",
            image = "https://www.d-tt.nl/assets/images/apps/vono-new-overview.png",
            url = "https://www.d-tt.nl/apps/vono-voice-note-to-mail-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.voicemail",
            categories = listOf(
                "Memo",
                "Notes",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "famiflora",
            title = "Famiflora",
            description = "Loyalty app to collect points and inform the user about products of the Famiflora gardening center.",
            image = "https://www.d-tt.nl/assets/images/apps/famiflora-new-overview.png",
            url = "https://www.d-tt.nl/apps/famiflora-loyalty-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.famiflora",
            categories = listOf(
                "Loyalty",
                "Shopping",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "breathbalanz",
            title = "BreathBalanz",
            description = "Breathing exercise program application.",
            image = "https://www.d-tt.nl/assets/images/apps/breathbalanz-new-overview.png",
            url = "https://www.d-tt.nl/apps/breathbalanz",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.breathbalanz",
            categories = listOf(
                "Healthcare",
                "Breathing",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "vormats",
            title = "Vormats",
            description = "App that lets users record & edit videos following a pre-written script.",
            image = "https://www.d-tt.nl/assets/images/apps/vormats-new-overview.png",
            url = "https://www.d-tt.nl/apps/vormats",
            categories = listOf(
                "Video",
                "Autocue",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "sportwallet",
            title = "SportWallet",
            description = "App that lets users earn points which can be used to get discounts by walking/running.",
            image = "https://www.d-tt.nl/assets/images/apps/sportwallet-new-overview.png",
            url = "https://www.d-tt.nl/apps/sportwallet",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.android.sportwallet",
            categories = listOf(
                "Sport",
                "Activity tracking",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
        Project(
            id = "hebban",
            title = "Hebban",
            description = "Book community app for the Hebban platform. Allow users to review and read information about books.",
            image = "https://www.d-tt.nl/assets/images/apps/xhebban-new-overview.png.pagespeed.ic.BwWkrwSYgo.webp",
            url = "https://www.d-tt.nl/apps/hebban-boeken-community-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.hebban",
            categories = listOf(
                "Books",
                "Reading",
            ),
            tasks = listOf(
                "Project Lead",
                "Android Development"
            )
        ),
        Project(
            id = "amsterdam-maps-routes",
            title = "Amsterdam Maps & Routes",
            description = "App to explore the city of Amsterdam through challenges and quizzes.",
            image = "https://www.d-tt.nl/assets/images/apps/xiamsterdam-new-overview.png.pagespeed.ic.Bx12HV-Pe9.webp",
            url = "https://www.d-tt.nl/apps/amsterdam-discovery-challenge-gps-app",
            downloadUrl = "https://play.google.com/store/apps/details?id=nl.dtt.adc",
            categories = listOf(
                "Informative",
                "Trivia",
            ),
            tasks = listOf(
                "Android Development"
            )
        ),
    )
}