package com.evertwoud.portfolio.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.core.Page
import com.evertwoud.portfolio.components.layouts.PageLayout
import com.evertwoud.portfolio.components.widgets.ContentBlock
import com.evertwoud.portfolio.data.Projects
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun AboutPage() {
    val projects = remember { Projects.projects }
    PageLayout("Work", backRoute = "/") {
        SimpleGrid(
            modifier = Modifier.fillMaxSize().padding(top = 64.px, bottom = 16.px, leftRight = 16.px),
            numColumns = numColumns(
                base = 1,
                md = 2,
                lg = 3,
                xl = 4
            )
        ) {
            projects.forEach { project ->
                ContentBlock(
                    modifier = Modifier.fillMaxSize(),
                    title = project.title,
                    link = "/work/${project.id}",
                    image = project.image
                )
            }
        }
    }
}
