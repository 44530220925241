package com.evertwoud.portfolio.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.core.Page
import com.evertwoud.portfolio.components.layouts.PageLayout
import com.evertwoud.portfolio.components.widgets.ContentBlock
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {
    var colorMode by ColorMode.currentState

    PageLayout("Home") {
        SimpleGrid(
            modifier = Modifier.fillMaxSize(),
            numColumns = numColumns(
                base = 1,
                lg = 2,
            )
        ) {

            Column(modifier = Modifier.fillMaxSize()) {
                Column(
                    modifier = Modifier.fillMaxWidth().padding(24.px),
                    verticalArrangement = Arrangement.Center,
                ) {
                    H1 { Text("EVERT WOUD") }
                    H4 { Text("SOFTWARE DEVELOPER @ ICAPPS") }
                    P {
                        Text("When I was 10 years old I started experimenting with creating my own websites and exploring different programming languages. Over the years I’ve gathered knowledge and experience in several programming languages.")
                        Br()
                        Br()
                        Text("Mobile Development (mainly Android) is currently my main expertise, but I like to explore different platforms.")
                    }
                }
                SimpleGrid(
                    modifier = Modifier.fillMaxSize().padding(16.px),
                    numColumns = numColumns(
                        base = 1,
                        sm = 2,
                    ),
                ) {
                    ContentBlock(
                        modifier = Modifier.fillMaxSize(),
                        title = "WORK",
                        link = "/work"
                    )
                    ContentBlock(
                        modifier = Modifier.fillMaxSize(),
                        title = "CONTACT",
                        link = "/contact"
                    )
                }
            }
            Image(
                modifier = Modifier
                    .fillMaxSize()
                    .background(colorMode.opposite.toSilkPalette().placeholder)
                    .objectFit(ObjectFit.Cover),
                src = "/profile.webp",
            )
        }
    }
}
