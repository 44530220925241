package com.evertwoud.portfolio.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.core.Page
import com.evertwoud.portfolio.components.layouts.PageLayout
import com.evertwoud.portfolio.components.widgets.ContentBlock
import com.evertwoud.portfolio.data.Projects
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Page("work/{id}")
@Composable
fun WorkDetail() {
    val colorMode by ColorMode.currentState
    val ctx = rememberPageContext()
    val project = remember {
        val id = ctx.route.params.getValue("id")
        Projects.projects.first { it.id == id }
    }

    PageLayout("Work - ${project.title}", backRoute = "/work") {
        SimpleGrid(
            modifier = Modifier.fillMaxSize(),
            numColumns = numColumns(
                base = 1,
                lg = 2,
            )
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                Image(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1F)
                        .background(colorMode.opposite.toSilkPalette().placeholder)
                        .objectFit(ObjectFit.Cover),
                    src = project.image,
                )
            }
            Column(modifier = Modifier.fillMaxSize()) {
                Column(
                    modifier = Modifier.padding(top = 64.px, bottom = 16.px, leftRight = 16.px)
                ) {
                    H1 { Text(project.title.uppercase()) }
                    Div(Modifier.height(32.px).toAttrs())
                    H3 { Text("Description") }
                    P { Text(project.description) }
                    Div(Modifier.height(12.px).toAttrs())
                    H3 { Text("Categories") }
                    P { Text(project.categories.joinToString()) }
                    Div(Modifier.height(12.px).toAttrs())
                    H3 { Text("Tasks") }
                    P { Text(project.tasks.joinToString()) }
                }
                SimpleGrid(
                    modifier = Modifier.padding(16.px).fillMaxWidth(),
                    numColumns = numColumns(
                        base = 1,
                        sm = 2,
                    )
                ) {
                    project.url?.let {
                        ContentBlock(
                            modifier = Modifier.fillMaxWidth(),
                            title = "READ MORE",
                            link = it
                        )
                    }
                    project.downloadUrl?.let {
                        ContentBlock(
                            modifier = Modifier.fillMaxWidth(),
                            title = "DOWNLOAD",
                            link = it
                        )
                    }
                }
            }
        }
    }
}
