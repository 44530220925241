package com.evertwoud.portfolio.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.dom.Span

@Composable
fun TablerIcon(
    name: String,
    modifier: Modifier = Modifier,
    size: FontSize = FontSize.XLarge
) {
    Span(
        attrs = modifier.fontSize(size).toAttrs { classes("ti", "ti-$name") }
    )
}