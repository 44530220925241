package com.evertwoud.portfolio.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

@Composable
fun ContentBlock(
    modifier: Modifier = Modifier,
    title: String,
    link: String,
    image: String? = null,
    strategy: OpenLinkStrategy = OpenLinkStrategy.IN_NEW_TAB
) {
    var colorMode by ColorMode.currentState
    Link(
        path = link,
        modifier = modifier.padding(8.px).textDecorationLine(TextDecorationLine.None),
        openExternalLinksStrategy = strategy
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .minHeight(192.px)
                .background(colorMode.opposite.toSilkPalette().background)
                .borderRadius(8.px),
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .color(colorMode.opposite.toSilkPalette().color)
                    .padding(16.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                H3(Modifier.weight(1F).toAttrs()) { Text(title.uppercase()) }
                H3(Modifier.toAttrs()) { Text("＋") }
            }
        }
    }
}