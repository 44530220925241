package com.evertwoud.portfolio.data

data class Project(
    val id: String,
    val title: String,
    val description: String,
    val image: String,
    val url: String? = null,
    val downloadUrl: String? = null,
    val tasks: List<String> = emptyList(),
    val categories: List<String> = emptyList(),
)