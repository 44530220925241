package com.evertwoud.portfolio

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.init.registerBaseStyle
import com.varabyte.kobweb.silk.theme.toSilkPalette
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.*

private const val COLOR_MODE_KEY = "portfolio:colorMode"

@InitSilk
fun initSilk(ctx: InitSilkContext) {
    ctx.theme.palettes.apply {
        // Light theme
        light.apply {
            background = Color.Companion.rgb(255,255,255)
            border = Color.Companion.rgb(0, 0, 0)
            color = Color.Companion.rgb(0, 0, 0)
        }
        // Dark theme
        dark.apply {
            background = Color.Companion.rgb(0, 0, 0)
            border = Color.Companion.rgb(255,255,255)
            color = Color.Companion.rgb(255,255,255)
        }
    }

    ctx.config.initialColorMode = localStorage.getItem(COLOR_MODE_KEY)?.let { ColorMode.valueOf(it) } ?: ColorMode.LIGHT
    ctx.stylesheet.apply {
        registerBaseStyle("h1") {
            Modifier.fontFamily("Sora", "sans-serif")
                .padding(bottom = 4.px)
                .margin(0.px)
                .fontWeight(500)
                .fontSize(300.percent)
        }
        registerBaseStyle("h2") {
            Modifier.fontFamily("Sora", "sans-serif")
                .padding(bottom = 2.px)
                .margin(0.px)
                .fontWeight(400)
        }
        registerBaseStyle("h3, h4, h5, h6") {
            Modifier.fontFamily("Sora", "sans-serif")
                .padding(bottom = 2.px)
                .margin(0.px)
                .fontWeight(300)
        }
        registerBaseStyle("body") {
            Modifier.fontFamily("DM Sans", "sans-serif")
                .padding(bottom = 2.px)
                .margin(0.px)
                .fontWeight(300)
        }
    }
}

@App
@Composable
fun Website(
    content: @Composable () -> Unit
) {
    SilkApp {
        val colorMode = ColorMode.current
        LaunchedEffect(colorMode) {
            localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
        }

        Surface(SmoothColorStyle.toModifier().minHeight(100.vh).background(colorMode.toSilkPalette().background)) {
            content()
        }
    }
}
